import React from "react"

import Lifestyle from "../ingredients/@laboratories.js"

export default function Laboratories_Single() {
  return (
    <>
      <Lifestyle />
    </>
  )
}